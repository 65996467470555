<template>
  <Modal
    v-model="modal"
    sticky
    scrollable
    :mask-closable="true"
    width="60%"
    @on-cancel="handleCancel"
    class="custom-modal-footer-block"
  >
    <template #header>
      <p class="title" v-if="!isEdit">添加机构</p>
      <p class="title" v-if="isEdit">修改机构</p>
      <div class="modal-close" @click="handleCancel">关闭 <span>ESC</span></div>
    </template>
    <div class="form">
      <Form ref="formValidate" label-position="top">
        <FormItem label="机构名称">
          <Input v-model="editObj.orgName" placeholder="请输入" />
        </FormItem>
      </Form>
    </div>
    <template #footer>
     
      <div class="btn btn-default" @click="submit" v-if="!isEdit">确认</div>
      <div class="btn btn-default" @click="handleEdit" v-if="isEdit">修改</div>
    </template>
  </Modal>
</template> 

<script setup>
import { Modal, Message } from "view-ui-plus";
import { ref, inject, onMounted, onBeforeUnmount } from "vue";
import qs from "qs";
import bus from "@/utils/bus";
let axios = inject("axios");
let modal = ref(false);
const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });

let formValidate = ref(null);
let isEdit = ref(false); //false为新增，true为修改
let orgName = ref("");
let editObj = ref({
  orgName: "",
}); //修改的数据
//修改机构
const handleEdit = () => {
  let url = `/setting/org/edit`;
  let params = {
    orgName: editObj.value.orgName,
    orgId: editObj.value.id,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success({
        background: true,
        content: "机构修改成功",
      });
    } else {
      Message.error({
        background: true,
        content: res.data.message,
      });
    }
    formValidate.value.resetFields();
    editObj.value = {
      orgName: "",
    };
    isEdit.value = false;
    modal.value = false;
    bus.emit("searchData");
  });
};
// 添加机构
const submit = () => {
  let url = `/setting/org/create`;
  let params = {
    orgName: editObj.value.orgName,
  };
  if (editObj.value.orgName == "") {
    return Message.error({
      background: true,
      content: "请输入机构名后再添加",
    });
  }
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success({
        background: true,
        content: "机构添加成功",
      });
    } else {
      Message.error({
        background: true,
        content: res.data.message,
      });
    }
    formValidate.value.resetFields();
    editObj.value = {
      orgName: "",
    };
    isEdit.value = false;
    modal.value = false;
    bus.emit("searchData");
  });
};
//取消
const handleCancel = () => {
  modal.value = false;
  isEdit.value = false;
  formValidate.value.resetFields();
  editObj.value = {
    orgName: "",
  };
};
onMounted(() => {

  //获取修改来的数据
  bus.on("editId", (row) => {
    isEdit.value = true;
    editObj.value = {...row};
    editObj.value.orgName = row.orgName;
  });
});
onBeforeUnmount(()=>{
  bus.all.delete('editId');
})
</script>

<style lang="scss" scoped>
</style>