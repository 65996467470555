<template>
  <div class="content-wrap">
    <Header />
    <div class="search-box">
      <div class="search">
        <ul>
          <li>
            <AutoComplete
              v-model="name"
              :data="nameList"
              @on-search="searchName"
              placeholder="请输入名称"
              class="width-400"
            >
              <Option v-for="item in nameList" :value="item" :key="item">{{
                item
              }}</Option>
            </AutoComplete>
          </li>
          <li>
            <div
              class="btn search-btn flex-center-center"
              @click="handleSearch"
            >
              <div class="img"></div>
            </div>
            <div
              class="btn reset-btn flex-center-center"
              @click="handleReset"
              v-if="name != ''"
            >
              <div class="img"></div>
            </div>
          </li>
        </ul>
        <div>
          <div class="static">
            <span
              >共 <span class="bold">{{ total }}</span
              >个</span
            >
          </div>
          <div class="btn btn-default" @click="openModal">添加机构</div>
        </div>
      </div>
    </div>
    <div class="table" v-if="total > 0">
      <Table :columns="columns" :data="tableData" border>
        <template #action="{ row }">
          <div class="flex">
            <div class="edit action-btn" @click="handleEdit(row)">
              <div class="img"></div>
            </div>
            <div class="del action-btn" @click="handleDel(row)">
              <div class="img"></div>
            </div>
          </div>
        </template>
      </Table>

      <div class="page" v-if="total > 10">
        <Page :total="total" show-elevator show-total @on-change="changePage" :page-size="pageSize"/>
      </div>
    </div>
    <noData v-if="total == 0" />
    <Footer />
    <orgModal ref="modal" />
    <Modal
      v-model="modal1"
      sticky
      scrollable
      :mask-closable="true"
      class="custom-modal-footer-block"
      @on-cancel="modal1 = false"
    >
      <template #header>
        <p class="title">提醒</p>
        <div class="modal-close" @click="modal1 = false">
          关闭 <span>ESC</span>
        </div>
      </template>
      <div class="alarm-info">你确定要删除吗？</div>
      <template #footer>
        <div class="btn btn-default" @click="confirm">确认</div>
      </template>
    </Modal>
  </div>
</template>
    
 <script setup>
import { ref, inject, onMounted ,onBeforeUnmount} from "vue";
import { Modal, Message } from "view-ui-plus";
import Header from "./components/orgHeader.vue";
import Footer from "@/components/footer.vue";
import noData from "./components/noData.vue";
import bus from "@/utils/bus.js";
import orgModal from "./components/orgModal.vue";
import qs from "qs";
let modal = ref(null);
let modal1 = ref(false);
let name = ref("");
let columns = [
  {
    title: "数据名称",
    key: "orgName",
    resizable: true,
  },
  {
    title: "更新人",
    key: "updater",
    align: "center",
    width: 300,
    resizable: true,
  },

  {
    title: "更新日期",
    key: "updateDate",
    // align: "center",
    width: 300,
    resizable: true,
  },
  {
    title: "操作",
    slot: "action",
    align: "center",
    width: 120,
    resizable: true,
  },
];
let tableData = ref([]);
let page = ref(1);
let pageSize = ref(10);
let total = ref(0);

const changePage = (val) => {
  page.value = val;
  getTableData();
};
let axios = inject("axios");
const getTableData = () => {
  let url = `/setting/org/list`;
  let params = {
    pageOffset: page.value,
    pageSize: pageSize.value,
    orgName: name.value,
  };

  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      tableData.value = data.content;
      total.value = data.count;
    }
  });
};

const handleSearch = () => {
  page.value = 1;
  getTableData();
};
const handleReset = () => {
  name.value = "";
  handleSearch();
};

let curData = ref({});
//删除文件
const handleDel = (row) => {
  curData.value = row;
  modal1.value = true;
};

// 确定删除
const confirm = () => {
  let row = curData.value;
  let url = `/setting/org/remove`;
  let params = {
    orgId: row.id,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success({
        background: true,
        content: "删除成功",
      });
      modal1.value = false;
      handleSearch();
    } else {
      Message.error({
        background: true,
        content: res.data.message,
      });
    }
  });
};
//编辑文件
const handleEdit = (row) => {
  openModal();
  bus.emit("editId", row);
};

//打开modal
const openModal = () => {
  modal.value.modify();
};

let nameList = ref([]);
const searchName = (value) => {
  let url = `/org/name`;
  let params = {
    orgName: value,
  };
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      nameList.value = res.data.data;
    } else {
      nameList.value = [];
    }
  });
};

onMounted(() => {
  handleSearch();
  bus.on("searchData", () => {
    handleSearch();
  });
});
onBeforeUnmount(()=>{
  bus.all.delete('searchData');
})
</script>
    
<style lang="scss" scoped>
@import "@/views/dataFilling/index.scss";
@import "./index.scss";
.edit .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/编辑-默认.png) no-repeat;
  background-size: cover;
}
.edit:hover .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/编辑-选中.png) no-repeat;
  background-size: cover;
}
.del .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/删除-默认.png) no-repeat;
  background-size: cover;
}

.del:hover .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/删除-选中.png) no-repeat;
  background-size: cover;
}
</style>